import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Tooltip, Spin } from "antd";
import { truncateString } from "./utils/stringUtils";
import {
  FaPlus,
  FaTrash,
  FaPlay,
  FaPause,
  FaTimes,
  FaEdit,
} from "react-icons/fa";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

const Alerts = ({ user }) => {
  const [alerts, setAlerts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newAlert, setNewAlert] = useState({
    collectionAddress: "",
    mode: "percentage",
    threshold: "10",
  });
  const [editingAlert, setEditingAlert] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  const [floorPrices, setFloorPrices] = useState({});

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchAlerts();
  }, []);

  const fetchAlerts = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const [alertsResponse, pricesResponse] = await Promise.all([
        axios.get(`${API_URL}/alerts`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/bulk-floor-prices`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setAlerts(alertsResponse.data);
      setFloorPrices(pricesResponse.data.floorPrices);
    } catch (error) {
      console.error("Fetch alerts and prices error:", error);
      message.error("Failed to fetch alerts and prices");
    } finally {
      setIsLoading(false);
    }
  };

  const parseCollectionSlug = (url) => {
    const trimmedUrl = url.trim().toLowerCase();
    const blurPattern = /^(?:https?:\/\/)?(?:www\.)?blur\.io\/(?:eth\/)?collection\/([^/?]+)/;
    const openSeaPattern = /^(?:https?:\/\/)?(?:www\.)?opensea\.io\/collection\/([^/?]+)/;
    const magicEdenPattern = /^(?:https?:\/\/)?(?:www\.)?magiceden\.io\/collections\/ethereum\/([^/?]+)/;
    const patterns = [blurPattern, openSeaPattern, magicEdenPattern];
    
    for (const pattern of patterns) {
      const match = trimmedUrl.match(pattern);
      if (match) {
        return match[1];
      }
    }
    return null;
  };

  const handleAddAlert = async (e) => {
    e.preventDefault();
    try {
      const collectionSlug = parseCollectionSlug(newAlert.collectionUrl);
      if (!collectionSlug) {
        throw new Error("Invalid collection URL");
      }

      const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/alerts`, 
        { 
          collectionSlug, 
          mode: newAlert.mode, 
          threshold: newAlert.threshold 
        }, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAlerts((prevAlerts) => [...prevAlerts, response.data]);
      setIsModalOpen(false);
      message.success("Alert added successfully");
      setNewAlert({
        collectionUrl: "",
        mode: "percentage",
        threshold: "10",
      });
    } catch (error) {
      console.error("Add alert error:", error);
      if (error.message === "Invalid collection URL") {
        message.error("Failed to add alert: Invalid collection URL");
      } else if (
        error.response &&
        error.response.data.message ===
          "Configure Telegram before adding an alert."
      ) {
        message.error(
          "Failed to add alert: Configure Telegram before adding an alert"
        );
      } else {
        message.error("Failed to add alert: " + (error.response?.data?.message || error.message));
      }
    }
  };

  const handleEditAlert = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");

      // Update the existing alert
      const response = await axios.patch(
        `${API_URL}/alerts/${editingAlert._id}`,
        {
          mode: editingAlert.mode,
          threshold: editingAlert.threshold,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        setAlerts((prevAlerts) =>
          prevAlerts.map((alert) =>
            alert._id === editingAlert._id ? response.data : alert
          )
        );
        setIsEditModalOpen(false);
        message.success("Alert updated successfully");
      } else {
        throw new Error("Failed to update alert");
      }
    } catch (error) {
      console.error("Edit alert error: ", error);
      message.error(
        "Failed to update alert: "
          (error.response?.data?.message || error.message)
      );
      // If the edit fails, fetch all alerts again to ensure the UI is in sync with the server
      fetchAlerts();
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${API_URL}/alerts/${id}/status`,
        {
          status: currentStatus === "Running" ? "Paused" : "Running",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data) {
        setAlerts((prevAlerts) =>
          prevAlerts.map((alert) => (alert._id === id ? response.data : alert))
        );
        message.success("Alert status updated");
      } else {
        throw new Error("No data received from server");
      }
    } catch (error) {
      console.error("Toggle status error:", error);
      message.error(
        "Failed to update alert status: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleDeleteAlert = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/alerts/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert._id !== id));
      message.success("Alert deleted successfully");
    } catch (error) {
      console.error("Delete alert error:", error);
      message.error(
        "Failed to delete alert: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAlert((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingAlert((prev) => ({ ...prev, [name]: value }));
  };

  const renderCollectionLinks = (alert) => {
    return (
      <span className="ml-0 flex items-center justify-start gap-2 md:gap-0  md:justify-center w-full md:w-fit">
        <a
          href={`https://blur.io/eth/collection/${alert.collectionSlug}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mr-2"
        >
          <img
            src="https://i.imgur.com/OB1kiVc.png"
            alt="Blur"
            className={`inline-block w-5 h-5`}
          />
        </a>
        <a
          href={`https://opensea.io/collection/${alert.collectionSlug}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mr-2"
        >
          <img
            src="https://i.imgur.com/u9J3pLH.png"
            alt="OpenSea"
            className={`inline-block w-5 h-5`}
          />
        </a>
        <a
          href={`https://magiceden.io/collections/ethereum/${alert.collectionSlug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.imgur.com/jX9WQ5J.png"
            alt="Magic Eden"
            className={`inline-block w-5 h-5`}
          />
        </a>
      </span>
    );
  };

  return (
    <div className={`space-y-6 ${isMobile ? "space-y-2 pb-20" : ""}`}>
      <div className={`space-x-2 flex justify-between items-center mt-14`}>
        <h2
          className={`px-3 text-gray-400 text-base font-bold ${
            isMobile ? "text-xs font-bold" : ""
          }`}
        >
          Live Alerts[{alerts.length}]
        </h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className={`px-3 py-1 bg-black text-gray-200 hover:bg-white hover:text-black border border-gray-400 rounded-md transition duration-300 flex items-center ${
            isMobile ? "text-xs" : ""
          }`}
        >
          <FaPlus className="mr-2" /> Add New Alert
        </button>
      </div>
      <div className="overflow-x-auto md:overflow-x-visible">
        <table className="w-full table-auto">
          <thead className="md:sticky top-16 left-0 z-50 shadow-2xl">
            <tr
              className={`bg-black text-sm text-gray-200 text-left border-b border-t border-gray-900 ${
                isMobile ? "text-xs" : ""
              }`}
            >
              <th
                className={`p-3 text-left text-gray-200 cursor-pointer sticky left-0 bg-black ${
                  isMobile ? "pr-1" : ""
                }`}
              >
                Collection
              </th>
              <th className="p-3">Condition</th>
              <th className="p-3">Current Floor</th>
              <th className="p-3">Last Alert</th>
              <th className="p-3">Last Alert Time</th>
              <th className="p-3 text-center">Status</th>
              <th className="p-3 text-center">Actions</th>
            </tr>
          </thead>
          {isLoading ? (
            <tr>
              <td
                colSpan="9"
                className="h-middle text-center py-4 text-gray-400"
              >
                <div
                  className={`flex justify-center items-center 
                  ${
                    isMobile
                      ? "h-screen fixed top-0 left-0 right-0 bg-black bg-opacity-50 z-50"
                      : "h-[400px]"
                  }`}
                >
                  <Spin
                    size="large"
                    className="custom-spin ant-spin-dot-item"
                  />
                </div>
                <style jsx>{`
                  .custom-spin .ant-spin-dot-item {
                    background-color: white;
                  }
                `}</style>
              </td>
            </tr>
          ) : (
            <tbody>
              {alerts.map((alert) => (
                <tr
                  key={alert._id}
                  className={`text-gray-300 ${
                    isMobile ? "text-xs" : "text-sm"
                  }`}
                >
                  <td
                    className={`p-3 text-left flex items-center cursor-pointer sticky left-0 bg-black min-w-40 ${
                      isMobile ? "pr-0 border-r border-gray-900" : ""
                    }`}
                  >
                    <div className="flex flex-col md:flex-row items-center w-full md:w-fit gap-1">
                      <div className="flex flex-row gap-2 items-center w-full md:w-fit overflow-clip">
                        <img
                          src={alert.collectionImage ||
                            "https://i.imgur.com/uJahu2i.png"}
                          alt={alert.collectionName}
                          className={`inline-block rounded-full w-8 h-8 mr-2 ${
                            isMobile ? "w-3 h-3" : ""
                          }`}
                        />
                        <p className={`mr-2 ${isMobile ? "mr-0" : ""} `}>
                          <Tooltip
                            title={alert.collectionName}
                            overlayInnerStyle={{
                              fontFamily: ["IBM Plex Mono", "monospace"],
                            }}
                          >
                            <p className={`mr-2 ${isMobile ? "mr-0" : ""}`}>
                              {truncateString(
                                alert.collectionName,
                                isMobile ? 15 : 25
                              )}
                            </p>
                          </Tooltip>
                        </p>
                      </div>
                      {renderCollectionLinks(alert)}
                    </div>
                  </td>
                  <td className="p-3">
                    {alert.mode === "percentage"
                      ? `Floor moves by ${alert.threshold}%`
                      : `Floor moves by ${alert.threshold} ETH`}
                  </td>
                  <td className="p-3">
                    {floorPrices[alert.collectionAddress]
                      ? parseFloat(
                          floorPrices[alert.collectionAddress]
                        ).toFixed(3)
                      : "-"}{" "}
                    ETH
                  </td>
                  <td className="p-3">
                    {alert.lastAlertTime
                      ? alert.referencePrice
                        ? parseFloat(alert.referencePrice).toFixed(3) + " ETH"
                        : "-"
                      : "-"}
                  </td>
                  <td className="p-3">
                    {alert.lastAlertTime
                      ? new Date(alert.lastAlertTime).toLocaleString()
                      : "-"}
                  </td>
                  <td className="p-0 text-center">
                    <span
                      className={`px-2 py-1 rounded ${
                        alert.status === "Running"
                          ? "text-green-500"
                          : "text-orange-500"
                      }`}
                    >
                      {alert.status}
                    </span>
                  </td>
                  <td className="p-3 text-center">
                    <button
                      onClick={() =>
                        handleToggleStatus(alert._id, alert.status)
                      }
                      className="mr-2 p-1 text-grey-300 hover:text-blue-700 transition duration-300"
                    >
                      {alert.status === "Running" ? <FaPause /> : <FaPlay />}
                    </button>
                    <button
                      onClick={() => {
                        setEditingAlert(alert);
                        setIsEditModalOpen(true);
                      }}
                      className="mr-2 p-1 text-grey-300 hover:text-yellow-500 transition duration-300"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteAlert(alert._id)}
                      className="p-1 text-grey-300 hover:text-red-700 transition duration-300"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="bg-black p-8 rounded-lg w-full max-w-md border border-gray-400">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-base text-white font-bold">Add New Alert</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-400 hover:text-white transition duration-300"
              >
                <FaTimes />
              </button>
            </div>
            <form onSubmit={handleAddAlert} className="space-y-4">
              <div>
                <label htmlFor="collectionUrl" className="block mb-1">
                  Collection URL
                </label>
                <input
                  id="collectionUrl"
                  name="collectionUrl"
                  value={newAlert.collectionUrl}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white placeholder-gray-700"
                  placeholder="Enter Blur/OpenSea/MagicEden URL"
                  required
                />
              </div>
              <div>
                <label htmlFor="mode" className="block mb-1">
                  Mode
                </label>
                <select
                  id="mode"
                  name="mode"
                  value={newAlert.mode}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
                >
                  <option value="percentage">% Change</option>
                  <option value="price">Price Difference</option>
                </select>
              </div>
              <div>
                <label htmlFor="threshold" className="block mb-1">
                  Threshold
                </label>
                <input
                  id="threshold"
                  name="threshold"
                  type="number"
                  value={newAlert.threshold}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
                  required
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="submit"
                  className="px-4 py-2 mt-6 bg-black text-white border border-gray-400 rounded-lg hover:bg-white hover:text-black transition duration-300"
                >
                  Add New Alert
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isEditModalOpen && editingAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="bg-black p-8 rounded-lg w-full max-w-md border border-gray-400">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl text-white font-bold">Edit Alert</h2>
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="text-gray-400 hover:text-white transition duration-300"
              >
                <FaTimes />
              </button>
            </div>
            <form onSubmit={handleEditAlert} className="space-y-4">
              <div>
                <label htmlFor="collectionName" className="block mb-1">
                  Collection
                </label>
                <input
                  id="ccollectionName"
                  name="collectionName"
                  value={editingAlert.collectionName}
                  className="w-full p-2 bg-black text-gray-400 border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
                  disabled
                />
              </div>
              <div>
                <label htmlFor="mode" className="block mb-1">
                  Mode
                </label>
                <select
                  id="mode"
                  name="mode"
                  value={editingAlert.mode}
                  onChange={handleEditInputChange}
                  className="w-full p-2 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
                >
                  <option value="percentage">% Change</option>
                  <option value="price">Price Difference</option>
                </select>
              </div>
              <div>
                <label htmlFor="threshold" className="block mb-1">
                  Threshold
                </label>
                <input
                  id="threshold"
                  name="threshold"
                  type="number"
                  value={editingAlert.threshold}
                  onChange={handleEditInputChange}
                  className="w-full p-2 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
                  required
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="submit"
                  className="px-4 py-2 mt-6 bg-black text-white border border-gray-400 rounded-lg hover:bg-white hover:text-black transition duration-300"
                >
                  Update Alert
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Alerts;
