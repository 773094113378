import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Tooltip, Spin } from "antd";
import { truncateString } from "./utils/stringUtils";
import {
  FaPlus,
  FaBell,
  FaRegBell,
  FaAngleUp,
  FaAngleDown,
  FaStar,
} from "react-icons/fa";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

const Watchlist = ({ user }) => {
  const [collections, setCollections] = useState([]);
  const [newCollectionUrl, setNewCollectionUrl] = useState("");
  const [sortColumn, setSortColumn] = useState("floorPrice");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user) {
      fetchWatchlist();
      fetchAlerts();
    }
  }, [user]);

  const fetchWatchlist = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/watchlist`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCollections(response.data);
    } catch (error) {
      console.error("Fetch watchlist error:", error);
      message.error("Failed to fetch watchlist data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAlerts = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/alerts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAlerts(response.data);
    } catch (error) {
      console.error("Fetch alerts error:", error);
      message.error("Failed to fetch alerts");
    }
  };

  const handleAddCollection = async () => {
    if (!newCollectionUrl) return;
    try {
      const token = localStorage.getItem("token");
      const slug = parseCollectionSlug(newCollectionUrl);
      if (!slug) {
        message.error("Invalid collection URL");
        return;
      }
      await axios.post(
        `${API_URL}/watchlist`,
        { slug },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setNewCollectionUrl("");
      message.success("Collection added successfully");
      fetchWatchlist();
    } catch (error) {
      console.error("Add collection error:", error);
      message.error("Failed to add collection");
    }
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedCollections = [...collections].sort((a, b) => {
    let valueA = a[sortColumn];
    let valueB = b[sortColumn];
  
    if (sortColumn === "name") {
      return sortDirection === "asc"
        ? (valueA || "").localeCompare(valueB || "")
        : (valueB || "").localeCompare(valueA || "");
    } else {
      valueA = valueA !== null ? parseFloat(valueA) : -Infinity;
      valueB = valueB !== null ? parseFloat(valueB) : -Infinity;
    }
    return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
  });

  const renderSortIcon = (column) => {
    if (column !== sortColumn) return null;
    return sortDirection === "asc" ? (
      <FaAngleUp className="inline ml-0" />
    ) : (
      <FaAngleDown className="inline ml-0" />
    );
  };

  const formatValue = (value, type) => {
    if (value === null || value === undefined || value === "") {
      return "-";
    }
    const num = parseFloat(value);
    if (isNaN(num)) return value;

    switch (type) {
      case "price":
      return num.toFixed(3);
      case "volume":
        return num.toFixed(2);
      case "count":
        return num.toFixed(0);
      default:
        return num.toString();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddCollection();
    }
  };

  const toggleAlert = async (collectionId) => {
    try {
      const token = localStorage.getItem("token");
      const existingAlert = alerts.find(
        (alert) => alert.collectionAddress === collectionId
      );

      if (existingAlert) {
        await axios.delete(`${API_URL}/alerts/${existingAlert._id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAlerts((prevAlerts) =>
          prevAlerts.filter((alert) => alert._id !== existingAlert._id)
        );
        message.success("Alert removed successfully");
      } else {
        const response = await axios.post(
          `${API_URL}/alerts/by-toggleicon`,
          {
            collectionId,
            mode: "percentage",
            threshold: 10,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data) {
          setAlerts((prevAlerts) => [...prevAlerts, response.data]);
          message.success("Alert added successfully with default conditions");
        } else {
          throw new Error("Failed to create alert");
        }
      }
    } catch (error) {
      console.error("Toggle alert error:", error);
      message.error(
        "Failed to toggle alert: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      fetchAlerts();
    }
  };

  const removeFromWatchlist = async (collectionId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/watchlist/remove`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { contractAddress: collectionId },
      });
      message.success("Collection removed from watchlist");
      fetchWatchlist(); // Refresh the watchlist after removal
    } catch (error) {
      console.error("Remove from watchlist error:", error);
      message.error("Failed to remove collection from watchlist");
    }
  };

  const parseCollectionSlug = (url) => {
    const trimmedUrl = url.trim().toLowerCase();
    const blurPattern =
      /^(?:https?:\/\/)?(?:www\.)?blur\.io\/(?:eth\/)?collection\/([^/?]+)/;
    const openSeaPattern =
      /^(?:https?:\/\/)?(?:www\.)?opensea\.io\/collection\/([^/?]+)/;
    const magicEdenPattern =
      /^(?:https?:\/\/)?(?:www\.)?magiceden\.io\/collections\/ethereum\/([^/?]+)/;
    const patterns = [blurPattern, openSeaPattern, magicEdenPattern];
    for (const pattern of patterns) {
      const match = trimmedUrl.match(pattern);
      if (match) {
        return match[1];
      }
    }
    return null;
  };

  const renderCollectionLinks = (collection) => {
    const hasAlert = alerts.some(
      (alert) => alert.collectionAddress === collection.id
    );
    return (
      <div className="ml-0 flex items-center justify-between md:justify-center w-full md:w-fit">
        <a
          href={`https://blur.io/eth/collection/${collection.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.imgur.com/OB1kiVc.png"
            alt="Blur"
            className={`inline-block w-5 h-5 `}
          />
        </a>
        <a
          href={`https://opensea.io/collection/${collection.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.imgur.com/u9J3pLH.png"
            alt="OpenSea"
            className={`inline-block w-5 h-5 `}
          />
        </a>
        <a
          href={`https://magiceden.io/collections/ethereum/${collection.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.imgur.com/jX9WQ5J.png"
            alt="Magic Eden"
            className={`inline-block w-5 h-5`}
          />
        </a>
        <button
          onClick={() => removeFromWatchlist(collection.id)}
          className={`ml-2 p-1 flex items-center text-yellow-500 hover:text-yellow-300 transition duration-300 ${
            isMobile ? "ml-0 p-0 text-left" : ""
          }`}
        >
          <FaStar />
        </button>
        <button
          onClick={() => toggleAlert(collection.id)}
          className={`ml-2 p-1 flex items-center ${
            hasAlert ? "text-yellow-500" : "text-gray-400"
          } hover:text-yellow-300 transition duration-300 ${
            isMobile ? "ml-0 p-0 text-left" : ""
          }`}
        >
          {hasAlert ? <FaBell /> : <FaRegBell />}
        </button>
      </div>
    );
  };


  const sourceImages = {
    "blur.io": "https://i.imgur.com/OB1kiVc.png",
    "opensea.io": "https://i.imgur.com/u9J3pLH.png",
    "magiceden.io": "https://i.imgur.com/jX9WQ5J.png",
    "magiceden.us": "https://i.imgur.com/fm5PRRs.png",
    "x2y2.io": "https://i.imgur.com/7uHYMtF.png",
    "looksrare.org": "https://i.imgur.com/Y1roawL.png",
    "okx.com": "https://i.imgur.com/bh9WnSI.png",
    "rarible.com": "https://i.imgur.com/jX0YQ1S.png",
    "nftx.io": "https://i.imgur.com/SbrpzY7.jpeg",
    "sudoswap.xyz": "https://i.imgur.com/6K1suE5.png",
    "foundation.app": "https://i.imgur.com/XBTePbf.png",
    "superrare.com": "https://i.imgur.com/8RyZhzO.png",
    "element.market": "https://i.imgur.com/nuQKh0M.png",
  };

  const getSourceDisplay = (sourceDomain) => {
    if (!sourceDomain) return null;
    
    if (sourceImages[sourceDomain]) {
      return (
        <a href={`https://${sourceDomain}`} target="_blank" rel="noopener noreferrer">
        <img
          src={sourceImages[sourceDomain]}
          alt={sourceDomain}
          className="w-4 h-4 inline-block ml-1 align-middle"
        />
        </a>
      );
    } else {
      return (
        <a
          href={`https://${sourceDomain}`}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block ml-1 align-middle text-xs bg-gray-800 text-gray-300 px-1 rounded"
          style={{ lineHeight: "20px", verticalAlign: "middle" }}
        >
          {sourceDomain.substring(0, 3).toUpperCase()}
        </a>
      );
    }
  };


  return (
    <div className={`space-y-6 ${isMobile ? "space-y-2 pb-20" : ""}`}>
      <div className={`space-x-2 flex justify-between items-center mt-14`}>
        <h2
          className={`px-3 text-gray-400 text-base font-bold ${
            isMobile ? "text-xs font-bold" : ""
          }`}
        >
          Watchlist[{collections.length}]
        </h2>
        <div className="flex items-center md:w-1/3">
          <input
            type="text"
            value={newCollectionUrl}
            onChange={(e) => setNewCollectionUrl(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter Blur/Opensea/MagicEden URL"
            className={`px-3 py-1 bg-black w-full text-gray-200 border border-gray-400 rounded-l-md focus:outline-none focus:ring-1 focus:ring-gray-500 ${
              isMobile ? "text-xs px-5" : ""
            }`}
          />
          <button
            onClick={handleAddCollection}
            className={`px-3 py-1 bg-black text-gray-200 rounded-r-md hover:bg-white hover:text-black border border-gray-400 transition duration-300 flex items-center ${
              isMobile ? "text-xs" : ""
            }`}
          >
            <FaPlus
              className={`mr-1 ml-1 mt-1 mb-1 ${
                isMobile ? "text-xs mr-0 ml-0 mt-0 mb-0" : ""
              }`}
            />
          </button>
        </div>
      </div>

      {/* Below Line for sticky */}
      <div className="overflow-x-auto md:overflow-x-visible">
        <table className="w-full table-auto">
          {/* Below Line for sticky */}
          <thead className="md:sticky top-16 left-0 z-50 shadow-2xl">
            <tr
              className={`text-gray-200 bg-black text-right text-sm border-b border-t border-gray-900 ${
                isMobile ? "text-xs" : ""
              }`}
            >
              <th
                className={`p-3 text-left text-gray-200 cursor-pointer sticky left-0 bg-black ${
                  isMobile ? "pr-1" : ""
                }`}
                onClick={() => handleSort("name")}
              >
                Collection {renderSortIcon("name")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("floorPrice")}
              >
                Floor {renderSortIcon("floorPrice")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("topBid")}
              >
                Top Bid {renderSortIcon("topBid")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("volume1d")}
              >
                1D Volume {renderSortIcon("volume1d")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("volume7d")}
              >
                7D Volume {renderSortIcon("volume7d")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("volume30d")}
              >
                30D Volume {renderSortIcon("volume30d")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("listed")}
              >
                Listed {renderSortIcon("listed")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("owners")}
              >
                Owners {renderSortIcon("owners")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("supply")}
              >
                Supply {renderSortIcon("supply")}
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan="9"
                  className="h-middle text-center py-4 text-gray-400"
                >
                  <div
                    className={`flex justify-center items-center 
                  ${
                    isMobile
                      ? "h-screen fixed top-0 left-0 right-0 bg-black bg-opacity-50 z-50"
                      : "h-[400px]"
                  }`}
                  >
                    <Spin
                      size="large"
                      className="custom-spin ant-spin-dot-item"
                    />
                  </div>
                  <style jsx>{`
                    .custom-spin .ant-spin-dot-item {
                      background-color: white;
                    }
                  `}</style>
                </td>
              </tr>
            ) : (
              sortedCollections.map((collection, index) => (
                <tr
                  key={index}
                  className={`bg-black text-sm text-right text-gray-300 ${
                    isMobile ? "text-xs" : ""
                  }`}
                >
                  <td
                    className={`p-3 text-left flex items-center cursor-pointer sticky left-0 bg-black md: min-w-20 ${
                      isMobile ? "pr-1 border-r border-gray-900" : ""
                    }`}
                  >
                    <div className="flex flex-col md:flex-row items-center w-full md:w-fit gap-1">
                      <div className="flex flex-row gap-2 items-center w-full md:w-fit overflow-clip">
                        <img
                          src={collection.image ||
                            "https://i.imgur.com/uJahu2i.png"} 
                          alt={collection.name}
                          className={`inline-block rounded-full w-8 h-8 mr-2 ${
                            isMobile ? "w-3 h-3" : ""
                          }`}
                        />
                        <p className={`mr-2 ${isMobile ? "mr-0" : ""} `}>
                          <Tooltip
                            title={collection.name}
                            overlayInnerStyle={{
                              fontFamily: ["IBM Plex Mono", "monospace"],
                            }}
                          >
                            <p className={`mr-2 ${isMobile ? "mr-0" : ""}`}>
                              {truncateString(
                                collection.name,
                                isMobile ? 15 : 25
                              )}
                            </p>
                          </Tooltip>
                        </p>
                      </div>
                      {renderCollectionLinks(collection)}
                    </div>
                  </td>
                  <td className="p-3">
                    {formatValue(collection.floorPrice, "price")}
                    {collection.floorPrice && collection.floorAskSource &&
                  getSourceDisplay(collection.floorAskSource)}
                  </td>
                  <td className="p-3">
                    {formatValue(collection.topBid, "price")}
                    {collection.topBid && collection.topBidSource &&
                  getSourceDisplay(collection.topBidSource)}
                  </td>
                  <td className="p-3">{formatValue(collection.volume1d, "volume")}</td>
                  <td className="p-3">{formatValue(collection.volume7d, "volume")}</td>
                  <td className="p-3">{formatValue(collection.volume30d, "volume")}</td>
                  <td className="p-3">{formatValue(collection.listed, "count")}</td>
                  <td className="p-3">{formatValue(collection.owners, "count")}</td>
                  <td className="p-3">{formatValue(collection.supply, "count")}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Watchlist;
