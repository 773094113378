import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

const TelegramModal = ({ isOpen, onClose, user }) => {
  const [botUsername, setBotUsername] = useState("");

  useEffect(() => {
    const fetchBotInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/telegram-bot-info`);
        setBotUsername(response.data.username);
      } catch (error) {
        console.error("Error fetching bot info:", error);
      }
    };

    fetchBotInfo();
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-black p-10 rounded-lg w-full max-w-md border border-gray-400">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-white ">Connect Telegram</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition duration-300"
          >
            <FaTimes />
          </button>
        </div>
        <div className="text-center">
          <p className="text-gray-300 mb-4">
            Scan this QR code or click the button below to configure the
            Telegram bot
          </p>
          <img
            src="https://i.imgur.com/4bWn8cX.png"
            alt="Telegram QR Code"
            className="mx-auto mb-8 w-1/2 h-1/2 border border-gray-400 rounded-xl "
          />
          <a
            href={`https://t.me/${botUsername}?start=${user ? user._id : ""}`}
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-white hover:text-black border border-gray-400 transition duration-300"
          >
            Click to Connect
          </a>
        </div>
      </div>
    </div>
  );
};

export default TelegramModal;
