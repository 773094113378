import React from 'react';

const AnimatedBackground = () => {
  return (
    <div className="fixed inset-0 overflow-hidden z-0">
      <div className="absolute inset-0 bg-black">
        <div className="absolute inset-0 animate-slide">
          <div className="absolute inset-0 bg-repeat bg-[length:40px_40px]" 
               style={{
                 backgroundImage: `radial-gradient(white 0.5px, transparent 0.5px)`,
                 width: '200%',
                 left: '-100%',
               }}>
          </div>
        </div>
      </div>
      <style jsx>{`
        @keyframes slide {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(50%);
          }
        }
        .animate-slide {
          animation: slide 50s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default AnimatedBackground;