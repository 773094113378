import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import {
  FaUser,
  FaSignOutAlt,
  FaBell,
  FaRegBell,
  FaRegStar,
  FaCoins,
  FaLayerGroup,
} from "react-icons/fa";
import Portfolio from "./portfolio";
import Watchlist from "./watchlist";
import Collections from "./collections";
import Alerts from "./alerts";
import ProfileModal from "./AccountModal";
import TelegramModal from "./TelegramModal";
import LoginPage from "./login.js";
import {
  ForgotPasswordPage,
  ResetPasswordPage,
} from "./PasswordResetComponents.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

//Test Line2
const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

const AppwithGoogle = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkTokenExpiration = useCallback(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken.exp < currentTime) {
          handleLogout();
          message.error("Your session has expired. Please log in again.");
          return false;
        }
        return true;
      } catch (error) {
        console.error("Error decoding token:", error);
        handleLogout();
        return false;
      }
    }
    return false;
  }, []);

  const fetchUserData = useCallback(
    async (token) => {
      try {
        if (!checkTokenExpiration()) return;
        const response = await axios.get(`${API_URL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUser(response.data);
      } catch (error) {
        console.error("Fetch user data error:", error);
        handleLogout();
      }
    },
    [checkTokenExpiration]
  );

  const fetchAlerts = useCallback(
    async (token) => {
      try {
        if (!checkTokenExpiration()) return;
        const response = await axios.get(`${API_URL}/alerts`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAlerts(response.data);
      } catch (error) {
        console.error("Fetch alerts error:", error);
        message.error("Failed to fetch alerts");
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      }
    },
    [checkTokenExpiration]
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && checkTokenExpiration()) {
      setIsLoggedIn(true);
      Promise.all([fetchUserData(token), fetchAlerts(token)])
        .then(() => setIsLoading(false))
        .catch(() => {
          setIsLoading(false);
          handleLogout();
        });
    } else {
      setIsLoading(false);
    }
  }, [fetchUserData, fetchAlerts, checkTokenExpiration]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && isLoggedIn) {
        checkTokenExpiration();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isLoggedIn, checkTokenExpiration]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setUser(null);
    setAlerts([]);
    message.success("Logged out successfully");
  };

  const handleLogin = async (token) => {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
    setIsLoading(true);
    try {
      await fetchUserData(token);
      await fetchAlerts(token);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black font-primary text-white ">
        Loading...
      </div>
    );
  }

  return (
    <Router>
      <div className="bg-black text-gray-400 min-h-screen font-primary flex flex-col">
        {isLoggedIn && (
          <Header
            handleLogout={handleLogout}
            setIsProfileModalOpen={setIsProfileModalOpen}
            setIsTelegramModalOpen={setIsTelegramModalOpen}
            isMobile={isMobile}
          />
        )}
        <main className="flex-grow">
          <Routes>
            <Route
              path="/forgot-password"
              element={
                isLoggedIn ? (
                  <Navigate to="/collections" replace />
                ) : (
                  <ForgotPasswordPage />
                )
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                isLoggedIn ? (
                  <Navigate to="/collections" replace />
                ) : (
                  <ResetPasswordPage />
                )
              }
            />
            <Route
              path="/login"
              element={
                isLoggedIn ? (
                  <Navigate to="/collections" replace />
                ) : (
                  <LoginPage handleLogin={handleLogin} />
                )
              }
            />
            <Route
              path="/collections"
              element={
                isLoggedIn ? (
                  <div className="p-4 md:p-10">
                    <Collections />
                  </div>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/portfolio"
              element={
                isLoggedIn ? (
                  <div className="p-4 md:p-10">
                    <Portfolio user={user} />
                  </div>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/watchlist"
              element={
                isLoggedIn ? (
                  <div className="p-4 md:p-10">
                    <Watchlist user={user} />
                  </div>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/alerts"
              element={
                isLoggedIn ? (
                  <div className="p-4 md:p-10">
                    <Alerts
                      alerts={alerts}
                      setAlerts={setAlerts}
                      fetchAlerts={() =>
                        fetchAlerts(localStorage.getItem("token"))
                      }
                    />
                  </div>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <Navigate to="/collections" replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>
        </main>

        {isMobile && <Footer />}

        {isProfileModalOpen && (
          <ProfileModal
            handleLogoutClick={() => {
              setIsProfileModalOpen(false);
              handleLogout();
            }}
            handleTgClick={() => {
              setIsProfileModalOpen(false);
              setIsTelegramModalOpen(true);
            }}
            user={user}
            setUser={setUser}
            isOpen={isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
          />
        )}
        {isTelegramModalOpen && (
          <TelegramModal
            isOpen={isTelegramModalOpen}
            onClose={() => setIsTelegramModalOpen(false)}
            user={user}
          />
        )}
      </div>
    </Router>
  );
};

const Header = ({
  handleLogout,
  setIsProfileModalOpen,
  setIsTelegramModalOpen,
  isMobile,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const navLinkClasses = ({ isActive }) =>
    `text-base text-gray-400 px-1 rounded-md hover:text-black hover:bg-white hover:rounded-md transition duration-300 ${
      isActive ? "text-white" : "font-normal"
    }`;

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
    setIsDropdownOpen(false);
  };

  const handleConfigureAlertsClick = () => {
    setIsTelegramModalOpen(true);
    setIsDropdownOpen(false);
  };

  const handleLogoutClick = () => {
    handleLogout();
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header
      className={`bg-black p-4 border-b border-gray-900 fixed top-0 left-0 right-0 !z-[60] ${
        isMobile ? "p-0" : ""
      }`}
    >
      <div className="md:px-6 flex justify-between items-center">
        <div className="flex items-center space-x-8">
          <div className="pl-3 flex items-center">
            <img
              src="/fv-logo-main.png"
              alt="Floor Alerts Logo"
              className="w-5 h-5 mr-1"
            />
            <h1
              className={`text-xl font-bold items-end mr-0 text-gray-100 ${
                isMobile ? "text-xl font-light" : ""
              }`}
            >
              FloorValue
            </h1>
          </div>
          {!isMobile && (
            <nav className="flex space-x-6 items-center ">
              <NavLink to="/collections" className={navLinkClasses}>
                Collections
              </NavLink>
              <NavLink to="/portfolio" className={navLinkClasses}>
                Portfolio
              </NavLink>
              <NavLink to="/watchlist" className={navLinkClasses}>
                Watchlist
              </NavLink>
              <NavLink to="/alerts" className={navLinkClasses}>
                Alerts
              </NavLink>
            </nav>
          )}
        </div>
        <div className="relative z-[60]" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="px-2 py-2 bg-black text-gray-200 hover:bg-white hover:text-black border border-gray-400 rounded-full transition duration-300 flex items-center"
          >
            <FaUser size={15} />
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-black border border-gray-400 rounded-lg shadow-lg z-[60]">
              <button
                onClick={handleProfileClick}
                className="block w-full text-left px-4 py-2 text-gray-200 hover:bg-white hover:text-black hover:rounded-lg transition duration-300"
              >
                <FaUser className="inline mr-2" /> Account
              </button>
              <button
                onClick={handleConfigureAlertsClick}
                className="block w-full text-left px-4 py-2 text-gray-200 hover:bg-white hover:text-black hover:rounded-lg transition duration-300"
              >
                <FaBell className="inline mr-2" /> Configure TG
              </button>
              <button
                onClick={handleLogoutClick}
                className="block w-full text-left px-4 py-2 text-gray-200 hover:bg-white hover:text-black hover:rounded-lg transition duration-300"
              >
                <FaSignOutAlt className="inline mr-2" /> Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

const Footer = () => {
  const location = useLocation();

  return (
    <footer className="bg-black border-t border-gray-900 fixed bottom-0 w-full">
      <div className="container mx-auto flex justify-between items-center px-4 py-3">
        <NavLink to="/collections" className="flex flex-col items-center ml-6">
          <FaLayerGroup
            size={15}
            className={
              location.pathname === "/collections"
                ? "text-gray-300"
                : "text-gray-600"
            }
          />
          <span
            className={`text-[10px] mt-1 ${
              location.pathname === "/collections"
                ? "text-gray-300"
                : "text-gray-600"
            }`}
          >
            Collections
          </span>
        </NavLink>
        <NavLink to="/portfolio" className="flex flex-col items-center">
          <FaCoins
            size={15}
            className={
              location.pathname === "/portfolio"
                ? "text-gray-300"
                : "text-gray-600"
            }
          />
          <span
            className={`text-[10px] mt-1 ${
              location.pathname === "/portfolio"
                ? "text-gray-300"
                : "text-gray-600"
            }`}
          >
            Portfolio
          </span>
        </NavLink>
        <NavLink to="/watchlist" className="flex flex-col items-center">
          <FaRegStar
            size={15}
            className={
              location.pathname === "/watchlist"
                ? "text-gray-300"
                : "text-gray-600"
            }
          />
          <span
            className={`text-[10px] mt-1 ${
              location.pathname === "/watchlist"
                ? "text-gray-300"
                : "text-gray-600"
            }`}
          >
            Watchlist
          </span>
        </NavLink>
        <NavLink to="/alerts" className="flex flex-col items-center mr-6">
          <FaRegBell
            size={15}
            className={
              location.pathname === "/alerts"
                ? "text-gray-300"
                : "text-gray-600"
            }
          />
          <span
            className={`text-[10px] mt-1 ${
              location.pathname === "/alerts"
                ? "text-gray-300"
                : "text-gray-600"
            }`}
          >
            Alerts
          </span>
        </NavLink>
      </div>
    </footer>
  );
};

export default AppwithGoogle;
