import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import LandingBG from "./LandingBG";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

// Component for requesting a password reset
export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_URL}/forgot-password`, { email });
      message.success(response.data.message);
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to send reset email");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col justify-center items-center min-h-screen font-primary">
      <LandingBG />
      <div className="z-10 text-center mb-8">
        <div className="flex items-center mb-2">
          <img src="/fv-logo-main.png" alt="Floor Alerts Logo" className="w-12 h-12 mr-2" />
          <h1 className="text-5xl text-white">FloorValue</h1>
        </div>
      </div>
      <div className="z-10 p-8 bg-black max-w-md w-full border border-gray-400 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-white text-center mb-4">Reset Password</h2>
        <p className="text-gray-400 text-center mb-6">
          Enter your email to receive a password reset link
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            required
            className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading}
            className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg hover:bg-white hover:text-black transition duration-300 disabled:opacity-50"
          >
            {isLoading ? "Sending..." : "Send Reset Link"}
          </button>
        </form>
        <div className="mt-4 text-center">
          <button
            onClick={() => navigate("/login")}
            className="text-gray-400 hover:text-white transition duration-300"
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

// Component for setting a new password after reset
export const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (password.length < 8) {
      message.error("Password must be at least 8 characters long");
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      message.error("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/reset-password/${token}`, {
        password,
      });
      message.success(response.data.message);
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to reset password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col justify-center items-center min-h-screen font-primary">
      <LandingBG />
      <div className="z-10 text-center mb-8">
        <div className="flex items-center mb-2">
          <img src="/fv-logo-main.png" alt="Floor Alerts Logo" className="w-12 h-12 mr-2" />
          <h1 className="text-5xl text-white">FloorValue</h1>
        </div>
      </div>
      <div className="z-10 p-8 bg-black max-w-md w-full border border-gray-400 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-white text-center mb-6">Set New Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New password (min 8 characters)"
            required
            className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
            disabled={isLoading}
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            required
            className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading}
            className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg hover:bg-white hover:text-black transition duration-300 disabled:opacity-50"
          >
            {isLoading ? "Resetting..." : "Reset Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default { ForgotPasswordPage, ResetPasswordPage };