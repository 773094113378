import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import LandingBG from "./LandingBG";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

const LoginPage = ({ handleLogin }) => {
  const [isSignup, setIsSignup] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (isSignup) {
        if (formValues.password.length < 8) {
          throw new Error("Password should be at least 8 characters long");
        }
        if (formValues.password !== formValues.confirmPassword) {
          throw new Error("Passwords do not match");
        }
      }

      const endpoint = isSignup ? "/signup" : "/login";
      const response = await axios.post(`${API_URL}${endpoint}`, formValues);
      handleLogin(response.data.token);
      message.success(isSignup ? "Signed up successfully" : "Signed in successfully");
      navigate("/collections");
    } catch (error) {
      console.error("Auth error:", error);
      message.error(
        error.response?.data?.message ||
          error.message ||
          `${isSignup ? "Sign up" : "Sign in"} failed. Please try again.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const res = await axios.post(`${API_URL}/auth/google`, {
        token: response.credential,
      });
      handleLogin(res.data.token);
      message.success("Signed in with Google successfully");
      navigate("/collections");
    } catch (error) {
      console.error("Google auth error:", error);
      message.error("Google authentication failed. Please try again.");
    }
  };

  return (
    <div className="relative flex flex-col justify-center items-center min-h-screen font-primary">
      <LandingBG />
      <div className="z-10 text-center mb-8">
        <div className="flex items-center mb-2">
          <img src="/fv-logo-main.png" alt="Floor Alerts Logo" className="w-12 h-12 mr-2" />
          <h1 className="text-5xl text-white">FloorValue</h1>
        </div>
        <p className="text-xm text-gray-400">Get NFT floor alerts on Telegram</p>
      </div>
      <div className="z-10 p-8 bg-black max-w-md w-full border border-gray-400 rounded-lg shadow-lg">
        <div className="flex justify-around mb-6">
          <button
            onClick={() => setIsSignup(false)}
            className={`flex-1 px-4 py-2 ${
              !isSignup ? "text-white border-b border-white" : "text-gray-400"
            } rounded-l transition duration-300`}
          >
            Sign in
          </button>
          <button
            onClick={() => setIsSignup(true)}
            className={`flex-1 px-4 py-2 ${
              isSignup ? "text-white border-b border-white" : "text-gray-400"
            } rounded-r transition duration-300`}
          >
            Sign up
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            name="email"
            type="email"
            placeholder="Email"
            value={formValues.email}
            onChange={handleInputChange}
            className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
            required
            disabled={isLoading}
          />
          <input
            name="password"
            type="password"
            placeholder={isSignup ? "Password (min 8 characters)" : "Password"}
            value={formValues.password}
            onChange={handleInputChange}
            className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
            required
            disabled={isLoading}
          />
          {isSignup && (
            <input
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              value={formValues.confirmPassword}
              onChange={handleInputChange}
              className="w-full p-3 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
              required
              disabled={isLoading}
            />
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full p-3 mt-2 bg-black text-white border border-gray-400 rounded-lg hover:bg-white hover:text-black transition duration-300 disabled:opacity-50"
          >
            {isSignup ? "Sign up" : "Sign in"}
          </button>
        </form>

        {!isSignup && (
          <div className="mt-4 text-center">
            <button
              onClick={() => navigate("/forgot-password")}
              className="text-gray-400 hover:text-white transition duration-300"
            >
              Forgot password?
            </button>
          </div>
        )}

        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-600" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-black text-gray-400">OR</span>
          </div>
        </div>

        <div className="mt-6 flex justify-center">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => message.error("Google authentication failed")}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;