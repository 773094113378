import React, { useState } from "react";
import axios from "axios";
import { message } from "antd";
import { FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

const AccountModal = ({
  user,
  setUser,
  isOpen,
  onClose,
  handleLogoutClick,
  handleTgClick,
}) => {
  const [password, setPassword] = useState("");
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [deleteConfirmPassword, setDeleteConfirmPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password && password.length < 8) {
        throw new Error("Password should be at least 8 characters long");
      }

      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${API_URL}/user`,
        { password },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUser(response.data);
      message.success("Profile updated successfully");
      onClose();
    } catch (error) {
      console.error("Update profile error:", error);
      message.error(error.message || "Failed to update profile");
    }
  };

  const handleResetPassword = async () => {
    await handleLogoutClick();
    navigate("/forgot-password");
  };

  const handleDeleteProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/user`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { password: deleteConfirmPassword },
      });
      message.success("Profile deleted successfully");
      localStorage.removeItem("token");
      window.location.reload();
    } catch (error) {
      console.error("Delete profile error:", error);
      message.error(
        "Failed to delete profile. Please check your password and try again."
      );
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-black p-8 rounded-lg w-full max-w-md border border-gray-400 ">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-white mb-4">User Profile</h2>
          <div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition duration-300"
            >
              <FaTimes />
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div>
            <span className="font-bold text-gray-400 mb-1">Email: </span>
            <span className="w-full  bg-black text-gray-400">{user.email}</span>
          </div>
          <div>
            <span className="font-bold text-gray-400 mb-1">Telegram: </span>
            <span
              className="w-full  bg-black text-gray-400"
              onClick={handleTgClick}
            >
              {user.telegramChatId ? (
                <span>
                  <span className="text-green-500">Connected. </span>
                  <span className="text-white border border-gray-400 rounded-lg px-2 py-1 cursor-pointer hover:bg-white hover:text-black ">Update</span>
                </span>
              ) : (
                <span>
                  <span className="text-red-600"> Not Connected. </span>
                  <span className="text-white border border-gray-400 rounded-lg px-2 py-1 cursor-pointer hover:bg-white hover:text-black ">Connect</span>
                </span>
              )}
            </span>
          </div>
          <div className="flex gap-4 justify-between items-center">
            <p
              className="mt-4 border text-red-600 text-center border-gray-300 px-1 py-1 rounded-md w-full cursor-pointer hover:bg-white hover:text-red-600"
              onClick={() => setIsDeleteConfirmOpen(true)}
            >
              Delete Profile
            </p>
            <p
              className="mt-4 text-white border text-center border-gray-300 px-1 py-1 rounded-md w-full cursor-pointer hover:bg-white hover:text-black"
              onClick={() => {
                handleResetPassword();
              }}
            >
              Reset Password
            </p>
          </div>
          {/* <div>
            <label className="block text-gray-400 mb-1">New Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password (min 8 characters)"
              className="w-full p-2 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-white"
            />
          </div> */}
          {/* <div className="flex justify-between">
            <button
              type="button"
              onClick={() => setIsDeleteConfirmOpen(true)}
              className="px-4 py-2 mt-6 bg-black text-red-600 border border-gray-400 rounded-lg hover:bg-white transition duration-300"
            >
              Delete Profile
            </button>
            <button
              type="submit"
              className="px-4 py-2 mt-6 bg-black text-white border border-gray-400 rounded-lg hover:bg-white hover:text-black transition duration-300"
            >
              Update Profile
            </button>
          </div> */}
        </div>

        {isDeleteConfirmOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center">
            <div className="bg-black p-8 rounded-lg w-full max-w-md border border-red-600 rounded-lg">
              <h3 className="text-lg font-bold text-white mb-4">
                Confirm Profile Deletion
              </h3>
              <p className="text-gray-300 mb-4">
                This action cannot be undone. Please enter your password to
                confirm.
              </p>
              <input
                type="password"
                value={deleteConfirmPassword}
                onChange={(e) => setDeleteConfirmPassword(e.target.value)}
                placeholder="Enter your password"
                className="w-full p-2 mb-4 bg-black text-white border border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-red-600"
              />
              <div className="flex justify-between">
                <button
                  onClick={() => setIsDeleteConfirmOpen(false)}
                  className="px-4 py-2 bg-black text-white border border-gray-400 rounded-lg hover:bg-white hover:text-black transition duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteProfile}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                >
                  Confirm Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountModal;
