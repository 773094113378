import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Tooltip, Spin } from "antd";
import { ethers } from "ethers";
import { truncateString } from "./utils/stringUtils";
import {
  FaPlus,
  FaTrash,
  FaWallet,
  FaTimes,
  FaBell,
  FaRegBell,
  FaAngleUp,
  FaAngleDown,
  FaStar,
  FaRegStar,
  FaCopy
} from "react-icons/fa";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
const ETHERSCAN_API_URL = "https://api.etherscan.io/api?module=stats&action=ethprice&apikey=1W7WGPN198KJ8CIMTIPHKIDWSTEHAU6JYJ";

const Portfolio = ({ user }) => {
  const [wallets, setWallets] = useState([]);
  const [newWallet, setNewWallet] = useState("");
  const [collections, setCollections] = useState([]);
  const [isWalletModalVisible, setIsWalletModalVisible] = useState(false);
  const [ethPrice, setEthPrice] = useState(0);
  const [isResolvingENS, setIsResolvingENS] = useState(false);
  const [portfolioMinValue, setPortfolioMinValue] = useState({
    eth: 0,
    usd: 0,
  });
  const [sortColumn, setSortColumn] = useState("floorPrice");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [watchlistStatus, setWatchlistStatus] = useState({});

  useEffect(() => {
    fetchWatchlistStatus();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user) {
      setWallets(user.wallets || []);
      fetchCollections();
      fetchEthPrice();
      fetchAlerts();
    }
  }, [user]);

  useEffect(() => {
    calculatePortfolioMinValue();
  }, [collections, ethPrice]);

  const fetchEthPrice = async () => {
    try {
      const response = await axios.get(ETHERSCAN_API_URL);
      const ethUsdPrice = response.data.result.ethusd;
      setEthPrice(parseFloat(ethUsdPrice));
    } catch (error) {
      console.error("Fetch ETH price error:", error);
      message.error("Failed to fetch ETH price");
    }
  };

  const fetchCollections = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/portfolio-sorted`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCollections(response.data);
    } catch (error) {
      console.error("Fetch collections error:", error);
      message.error("Failed to fetch portfolio data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAlerts = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/alerts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAlerts(response.data);
    } catch (error) {
      console.error("Fetch alerts error:", error);
      message.error("Failed to fetch alerts");
    }
  };

  const isValidEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const isValidENS = (name) => {
    return /^[a-z0-9-]+\.eth$/.test(name.toLowerCase());
  };

  const resolveENS = async (ensName) => {
    try {
      const provider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/7fc933d73417435fbcda0359a385daaf");
      const address = await provider.resolveName(ensName);
      return address;
    } catch (error) {
      console.error("Error resolving ENS:", error);
      return null;
    }
  };

  const handleAddWallet = async () => {
    if (!newWallet) return;

    setIsResolvingENS(true);
    let addressToAdd = newWallet;

    if (isValidENS(newWallet)) {
      const resolvedAddress = await resolveENS(newWallet);
      if (!resolvedAddress) {
        message.error("Failed to resolve ENS name. Please check and try again.");
        setIsResolvingENS(false);
        return;
      }
      addressToAdd = resolvedAddress;
    } else if (!isValidEthereumAddress(newWallet)) {
      message.error("Invalid Ethereum address or ENS name. Please check and try again.");
      setIsResolvingENS(false);
      return;
    }
    
    const walletExists = wallets.some(wallet => wallet.toLowerCase() === addressToAdd.toLowerCase());
    
    if (walletExists) {
      message.error("This wallet address already exists in your portfolio.");
      setIsResolvingENS(false);
      return;
    }
    
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${API_URL}/wallets`,
        { address: addressToAdd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setWallets([...wallets, addressToAdd]);
      setNewWallet("");
      message.success("Wallet added successfully");
      fetchCollections();
    } catch (error) {
      console.error("Add wallet error:", error);
      message.error("Failed to add wallet");
    } finally {
      setIsResolvingENS(false);
    }
  };


  const handleDeleteWallet = async (wallet) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/wallets/${wallet}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWallets(wallets.filter((w) => w !== wallet));
      message.success("Wallet deleted successfully");
      fetchCollections();
    } catch (error) {
      console.error("Delete wallet error:", error);
      message.error("Failed to delete wallet");
    }
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedCollections = [...collections].sort((a, b) => {
    let valueA = a[sortColumn];
    let valueB = b[sortColumn];
  
    if (sortColumn === "name") {
      return sortDirection === "asc"
        ? (valueA || "").localeCompare(valueB || "")
        : (valueB || "").localeCompare(valueA || "");
    } else {
      valueA = valueA !== null ? parseFloat(valueA) : -Infinity;
      valueB = valueB !== null ? parseFloat(valueB) : -Infinity;
    }
    return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
  });

  const renderSortIcon = (column) => {
    if (column !== sortColumn) return null;
    return sortDirection === "asc" ? (
      <FaAngleUp className="inline ml-0" />
    ) : (
      <FaAngleDown className="inline ml-0" />
    );
  };

  const calculatePortfolioMinValue = () => {
    if (!Array.isArray(collections)) {
      console.error("collections is not an array:", collections);
      message.error("Backend error");
      setPortfolioMinValue({ eth: 0, usd: 0 });
      return;
    }

    const totalEthValue = collections.reduce((sum, collection) => {
      const floorPrice = parseFloat(collection.floorPrice) || 0;
      const count = parseInt(collection.count) || 0;
      const volume30d = parseFloat(collection.volume30d) || 0;

      // Only include collections with non-zero 30-day volume
      if (volume30d > 0) {
        return sum + floorPrice * count;
      }
      return sum;
    }, 0);

    const totalUsdValue = totalEthValue * ethPrice;

    setPortfolioMinValue({
      eth: totalEthValue,
      usd: totalUsdValue,
    });
  };

  const formatValue = (value, type) => {
    if (value === null || value === undefined || value === "") {
      return "-";
    }
    const num = parseFloat(value);
    if (isNaN(num)) return value;

    switch (type) {
      case "price":
        return num.toFixed(3);
      case "volume":
        return num.toFixed(2);
      case "count":
        return num.toFixed(0);
      default:
        return num.toString();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddWallet();
    }
  };

  const toggleAlert = async (collectionId) => {
    try {
      const token = localStorage.getItem("token");
      const existingAlert = alerts.find(
        (alert) => alert.collectionAddress === collectionId
      );

      if (existingAlert) {
        // Delete existing alert
        await axios.delete(`${API_URL}/alerts/${existingAlert._id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAlerts((prevAlerts) =>
          prevAlerts.filter((alert) => alert._id !== existingAlert._id)
        );
        message.success("Alert removed successfully");
      } else {
        // Create new alert
        const response = await axios.post(
          `${API_URL}/alerts/by-toggleicon`,
          {
            collectionId,
            mode: "percentage",
            threshold: 10,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data) {
          setAlerts((prevAlerts) => [...prevAlerts, response.data]);
          message.success("Alert added successfully with default conditions");
        } else {
          throw new Error("Failed to create alert");
        }
      }
    } catch (error) {
      console.error("Toggle alert error:", error);
      if (error.response && error.response.status === 400 && error.response.data.message === "Configure Telegram before adding an alert.") {
        message.error("Please configure Telegram in your profile before adding an alert");
      } else {
        message.error(
          "Failed to toggle alert: " +
            (error.response?.data?.message || error.message)
        );
      }
    } finally {
      // Fetch alerts again to ensure UI is in sync with server
      fetchAlerts();
    }
  };

  const fetchWatchlistStatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/watchlist/status`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWatchlistStatus(response.data);
    } catch (error) {
      console.error("Fetch watchlist status error:", error);
    }
  };

  const toggleWatchlist = async (collectionId) => {
    try {
      const token = localStorage.getItem("token");
      if (watchlistStatus[collectionId]) {
        await axios.delete(`${API_URL}/watchlist/remove`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { contractAddress: collectionId },
        });
        message.success("Collection removed from watchlist");
      } else {
        await axios.post(
          `${API_URL}/watchlist/add`,
          { contractAddress: collectionId, slug: collectionId }, // You might need to add a slug field to your collection data
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        message.success("Collection added to watchlist");
      }
      setWatchlistStatus((prevStatus) => ({
        ...prevStatus,
        [collectionId]: !prevStatus[collectionId],
      }));
    } catch (error) {
      console.error("Toggle watchlist error:", error);
      message.error("Failed to update watchlist");
    }
  };

  const truncateAddress = (address) => {
    if (isMobile) {
      return `${address.slice(0, 4)}....${address.slice(-4)}`;
    }
    return address;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success("Address copied to clipboard");
    });
  };

  const renderCollectionLinks = (collection) => {
    const hasAlert = alerts.some(
      (alert) => alert.collectionAddress === collection.id
    );
    const isInWatchlist = watchlistStatus[collection.id];
    return (
      <div className="ml-0 flex items-center justify-between md:justify-center w-full md:w-fit">
        <a
          href={`https://blur.io/eth/collection/${collection.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.imgur.com/OB1kiVc.png"
            alt="Blur"
            className={`inline-block w-5 h-5 `}
          />
        </a>
        <a
          href={`https://opensea.io/collection/${collection.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.imgur.com/u9J3pLH.png"
            alt="OpenSea"
            className={`inline-block w-5 h-5 `}
          />
        </a>
        <a
          href={`https://magiceden.io/collections/ethereum/${collection.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.imgur.com/jX9WQ5J.png"
            alt="Magic Eden"
            className={`inline-block w-5 h-5`}
          />
        </a>
        <button
          onClick={() => toggleWatchlist(collection.id)}
          className={`ml-2 p-1 flex items-center ${
            isInWatchlist ? "text-yellow-500" : "text-gray-400"
          } hover:text-yellow-300 transition duration-300 ${
            isMobile ? "ml-0 p-0 text-left" : ""
          }`}
        >
          {isInWatchlist ? <FaStar /> : <FaRegStar />}
        </button>
        <button
          onClick={() => toggleAlert(collection.id)}
          className={`ml-2 p-1 flex items-center ${
            hasAlert ? "text-yellow-500" : "text-gray-400"
          } hover:text-yellow-300 transition duration-300 ${
            isMobile ? "ml-0 p-0 text-left" : ""
          }`}
        >
          {hasAlert ? <FaBell /> : <FaRegBell />}
        </button>
      </div>
    );
  };

  return (
    <div className={`space-y-6 ${isMobile ? "space-y-2 pb-20" : ""}`}>
      <div className={`space-x-2 flex justify-between items-center mt-14`}>
        <h2
          className={`px-3 text-gray-400 text-base font-bold ${
            isMobile ? "text-xs font-bold" : ""
          }`}
        >
          Portfolio Floor: ${portfolioMinValue.usd.toFixed(2)} (
          {portfolioMinValue.eth.toFixed(2)} ETH)
        </h2>
        <button
          onClick={() => setIsWalletModalVisible(true)}
          className={`px-3 py-1 bg-black text-base text-gray-200 rounded hover:bg-white hover:text-black border border-gray-400 rounded-md transition duration-300 flex items-center ${
            isMobile ? "text-xs" : ""
          }`}
        >
          <FaWallet className="mr-2" /> Manage Wallets
        </button>
      </div>

      {isWalletModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" style={{ zIndex: 60 }}>
          <div className="bg-black border border-gray-400 rounded-lg w-full max-w-2xl p-8">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-white">Your Wallets</h2>
              <button
                onClick={() => setIsWalletModalVisible(false)}
                className="text-gray-400 hover:text-white transition duration-300"
              >
                <FaTimes />
              </button>
            </div>
            <div className="overflow-y-auto mb-4">
              {wallets.map((wallet) => (
                <div
                  key={wallet}
                  className="flex items-center justify-between bg-black p-3 mb-4 border border-gray-400 rounded-lg"
                >
                  <span className="text-sm text-gray-200 mr-2">{truncateAddress(wallet)}</span>
                  <div className="flex items-center">
                    <button
                      onClick={() => copyToClipboard(wallet)}
                      className="p-1 text-gray-400 hover:text-white transition duration-300 mr-2"
                    >
                      <FaCopy />
                    </button>
                    <a
                      href={`https://etherscan.io/address/${wallet}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-1 text-gray-400 hover:text-white transition duration-300 mr-2"
                    >
                      <img src="https://etherscan.io/images/favicon3.ico" alt="Etherscan" className="w-4 h-4" />
                    </a>
                    <a
                      href={`https://blur.io/${wallet}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-1 text-gray-400 hover:text-white transition duration-300 mr-2"
                    >
                      <img src="https://i.imgur.com/OB1kiVc.png" alt="Blur" className="w-6 h-6" />
                    </a>
                    <a
                      href={`https://opensea.io/${wallet}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-1 text-gray-400 hover:text-white transition duration-300 mr-2"
                    >
                      <img src="https://i.imgur.com/u9J3pLH.png" alt="OpenSea" className="w-6 h-6" />
                    </a>
                    <button
                      onClick={() => handleDeleteWallet(wallet)}
                      className="p-1 text-gray-400 hover:text-red-500 transition duration-300"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-4 mt-10">
              <div className="flex items-center p-3 justify-between bg-black border border-gray-400 rounded-lg">
                <input
                  className="flex-grow text-white bg-black focus:outline-none"
                  value={newWallet}
                  onChange={(e) => setNewWallet(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Add ENS name or Ethereum wallet address"
                />
                <button
                  onClick={handleAddWallet}
                  disabled={isResolvingENS}
                  className={`p-1 text-xl text-gray-300 hover:text-2xl hover:text-gray-100 transition duration-300 mr-0 ${isResolvingENS ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isResolvingENS ? (
                    <div className="wallet-spin-container">
                      <Spin className="custom-spin ant-spin-dot-item"/>
                      <style jsx>{`
                        .wallet-spin-container :global(.ant-spin-dot-item) {
                          background-color: white !important;
                        }
                      `}</style>
                    </div>
                  ) : (
                    <FaPlus />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="overflow-x-auto md:overflow-x-visible">
        <table className="w-full table-auto  ">
          <thead className={`md:sticky top-16 left-0 z-50 shadow-2xl ${
                isMobile ? "top-0" : ""
              }`}
              >
            <tr
              className={`text-gray-200 bg-black text-right text-sm border-b border-t border-gray-900  ${
                isMobile ? "text-xs" : ""
              }`}
            >
              <th
                className={`p-3 text-left text-gray-200 cursor-pointer sticky left-0 bg-black ${
                  isMobile ? "pr-1" : ""
                }`}
                onClick={() => handleSort("name")}
              >
                Collection {renderSortIcon("name")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("floorPrice")}
              >
                Floor {renderSortIcon("floorPrice")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("topBid")}
              >
                Top Bid {renderSortIcon("topBid")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("volume1d")}
              >
                1D Volume {renderSortIcon("volume1d")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("volume7d")}
              >
                7D Volume {renderSortIcon("volume7d")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("volume30d")}
              >
                30D Volume {renderSortIcon("volume30d")}
              </th>
              <th
                className="p-3 cursor-pointer"
                onClick={() => handleSort("count")}
              >
                Count {renderSortIcon("count")}
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan="7"
                  className="h-middle text-center py-4 text-gray-400"
                >
                  <div
                    className={`flex justify-center items-center 
                  ${
                    isMobile
                      ? "h-screen fixed top-0 left-0 right-0 bg-black bg-opacity-50 z-50"
                      : "h-[400px]"
                  }`}
                  >
                    <Spin
                      size="large"
                      className="custom-spin ant-spin-dot-item"
                    />
                  </div>
                  <style jsx>{`
                    .custom-spin .ant-spin-dot-item {
                      background-color: white;
                    }
                  `}</style>
                </td>
              </tr>
            ) : (
              sortedCollections.map((collection, index) => (
                <tr
                  key={index}
                  className={`bg-black text-sm text-right text-gray-300 min-w-40 ${
                    isMobile ? "text-xs" : ""
                  }`}
                >
                  <td
                    className={`p-3 text-left flex items-center cursor-pointer sticky left-0 bg-black  ${
                      isMobile ? "pr-1 border-r border-gray-900" : ""
                    }`}
                  >
                    <div className="flex flex-col md:flex-row items-center w-full md:w-fit  gap-1">
                      <div className="flex flex-row gap-2 items-center w-full md:w-fit overflow-clip">
                        <img
                          src={collection.image ||
                            "https://i.imgur.com/uJahu2i.png"}
                          alt={collection.name}
                          className={`inline-block rounded-full w-8 h-8 mr-2 ${
                            isMobile ? "w-3 h-3" : ""
                          }`}
                        />
                        <p className={`mr-2 ${isMobile ? "mr-0" : ""} `}>
                          <Tooltip
                            title={collection.name}
                            overlayInnerStyle={{
                              fontFamily: ["IBM Plex Mono", "monospace"],
                            }}
                          >
                            <p className={`mr-2 ${isMobile ? "mr-0" : ""}`}>
                              {truncateString(
                                collection.name,
                                isMobile ? 15 : 25
                              )}
                            </p>
                          </Tooltip>
                        </p>
                      </div>
                      {renderCollectionLinks(collection)}
                    </div>
                  </td>
                  <td className="p-3">{formatValue(collection.floorPrice, "price")}</td>
                  <td className="p-3">{formatValue(collection.topBid, "price")}</td>
                  <td className="p-3"> {formatValue(collection.volume1d, "volume")}</td>
                  <td className="p-3"> {formatValue(collection.volume7d, "volume")}</td>
                  <td className="p-3">{formatValue(collection.volume30d, "volume")}</td>
                  <td className="p-3">{formatValue(collection.count, "count")}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Portfolio;
